import { ElementType, Fragment, MouseEvent } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const AppMenu: ElementType = (props) => {

    const { selectedIndex, handleClick } = props;
    return (
        <Fragment>
            <ListItemButton selected={ selectedIndex === 0 } onClick={ _ => handleClick(0) }>
                <ListItemIcon onClick={ _ => handleClick(0) }>
                    <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Search" />
            </ListItemButton>
            <ListItemButton selected={ selectedIndex === 1 } onClick={ _ => handleClick(1) }>
                <ListItemIcon>
                    <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="File upload" />
            </ListItemButton>
            <ListItemButton selected={ selectedIndex === 2 } onClick={ _ => handleClick(2) }>
                <ListItemIcon >
                    <AutoAwesomeIcon />
                </ListItemIcon>
                <ListItemText primary="Asistant" />
            </ListItemButton>
        </Fragment>
    );
}
