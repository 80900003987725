import { useCallback, useState } from 'react';

interface CookieOptions {
    days?: number;
    path?: string;
    domain?: string;
    SameSite?: 'None' | 'Lax' | 'Strict';
    Secure?: boolean;
    HttpOnly?: boolean;
}

interface updateItem {
    (value: string, options?: CookieOptions): void;
}

interface removeItem {
    (): void;
}

const isBrowser = typeof window !== 'undefined';

export function stringifyOptions(options: any): string {
    return Object.keys(options).reduce((acc: string, key: string) => {
        if (key === 'days') {
            return acc;
        } else {
            if (options[key] === false) {
                return acc;
            } else if (options[key] === true) {
                return `${acc}; ${key}`;
            } else {
                return `${acc}; ${key}=${options[key]}`;
            }
        }
    }, '');
}

export const setCookie = (name: string,
                          value: string,
                          options?: CookieOptions): void => {
    if (!isBrowser) return;
    const optionsWithDefaults = {
        days: 7,
        path: '/',
        ...options,
    };

    const expires = new Date(
        Date.now() + optionsWithDefaults.days * 864e5
    ).toUTCString();

    document.cookie =
        name +
        '=' +
        encodeURIComponent(value) +
        '; expires=' +
        expires +
        stringifyOptions(optionsWithDefaults);
};

export const getCookie = (name: string, initialValue?: string): string | undefined => {
    return (
        (isBrowser &&
            document.cookie.split('; ').reduce((r, v) => {
                const parts = v.split('=');
                return parts[0] === name ? decodeURIComponent(parts[1]) : r;
            }, '')) ||
        initialValue
    );
};

export const removeCookie = (name: string): void => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const useCookie = (key: string, initialValue?: string): [string | undefined, updateItem, removeItem] => {
    const [item, setItem] = useState<string | undefined>(() => {
        return getCookie(key, initialValue);
    });

    const removeItem = useCallback(() => {
        setItem(undefined);
        removeCookie(key);
    }, [key]);

    const updateItem = useCallback((value: string, options?: CookieOptions) => {
        setItem(value);
        setCookie(key, value, options);
    }, [key]);

    return [item, updateItem, removeItem];
}

export default useCookie;