import httpClient from './apiClient'

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await httpClient.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};