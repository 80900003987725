import { createContext, useContext } from 'react';
import { IAppContext } from './types';

const AppContext = createContext<IAppContext | undefined>(undefined);

// custom hook for the state
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error("useAuthenticationStateContext must be used within a AuthenticationStateContext.Provider");
    }
    return context;
};

export default AppContext;