import React, { useCallback, useState } from 'react';
import { Paper, Button, Typography, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../state/fileUpload';

const FileUpload = () => {
    const [dragging, setDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const files: FileList = e.dataTransfer.files;
        if (files && files.length > 0) {
            try {
                const response = await uploadFile(files[0]);
                console.log('File uploaded successfully', response);
                setErrorMessage('');
            } catch (error) {
                console.error('Error uploading file', error);
                setErrorMessage('Error uploading file. Please try again.');
            }
        }
    }, []);

    const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files;
        if (files && files.length > 0) {
            uploadFile(files[0])
                .then(response => {
                    console.log('File uploaded successfully', response);
                    setErrorMessage(''); // Clear any previous error message
                })
                .catch(error => {
                    console.error('Error uploading file', error);
                    setErrorMessage('Error uploading file. Please try again.');
                });
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
            <Paper
                elevation={3}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                    border: dragging ? '2px dashed #000' : '2px dashed #aaa',
                    padding: 20,
                    width: 400,
                    height: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '10px',
                }}
            >
                <CloudUploadIcon style={{ fontSize: 50, marginBottom: 20 }} />
                <Typography variant="body1">
                    {dragging ? "Drop here..." : "Drag 'n' drop a file here, or click to select a file"}
                </Typography>
                <Button variant="contained" component="label" style={{ marginTop: 20 }}>
                    Upload File
                    <input type="file" hidden onChange={handleFileSelect} />
                </Button>
            </Paper>
            {errorMessage && <Alert severity="error" style={{ width: 300 }}>{errorMessage}</Alert>}
        </div>
    );
};

export default FileUpload;