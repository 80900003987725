import React, { ElementType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../state/app/context';

const Authenticated: ElementType = ( { children }) => {
  const { state: { isLoading, user } } = useAppContext()
  const location = useLocation();

  return isLoading || user ?
    ( children ) :
    ( <Navigate to="/signin" replace state={{ path: location.pathname }} /> );
}

export default Authenticated;