import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Authenticated from './components/Authenticated';
import { AppProvider } from './state/app/provider';
import Landing from './pages/Landing';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Home from './pages/Home';

function App() {
    return (
        <AppProvider>
            <Router>
                <Routes>
                    <Route path="/home" element={
                        <Authenticated>
                            <Home />
                        </Authenticated>} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/" element={<Landing />} />
                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        </AppProvider>
    );
}

export default App;
