import React, { ReactNode, useEffect, useReducer } from 'react';
import { AppStateReducer } from './reducer';
import AppContext from './context';
import { AppAction } from '../../constants/actions';
import useCookie from '../../hooks/useCookie';
import apiClient from '../apiClient';

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const [sid] = useCookie('app.sid');
    const [uat] = useCookie('app.uat');
    const [state, dispatch] = useReducer(AppStateReducer, {
        isLoading: true,
    });

    useEffect(() => {
        if (!sid || (sid && uat && !state.user)) {
            apiClient
                .get('/')
                .then((response) => {
                    console.log('session init: ' + response.status);
                    if (response.status === 200) {
                        if (response.data && uat) {
                            const { email, name } = response.data;
                            dispatch({ type: AppAction.SIGN_IN, user: { email, name, accessToken: uat } });
                            return;
                        } else {
                            dispatch({ type: AppAction.APP_INIT });
                        }
                    }
                })
                .catch((error) => {
                    throw new Error(error);
                });
        } else {
            dispatch({ type: AppAction.APP_INIT });
        }
    }, [sid, uat, state.user]);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};