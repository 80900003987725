import { AppAction } from '../../constants/actions';
import { AppActions, IAppState } from './types';

export const AppStateReducer = (state: IAppState, action: AppActions): IAppState => {
    switch (action.type) {
        case AppAction.APP_INIT: {
            console.log("Action: " + AppAction.APP_INIT);
            return {
                ...state,
                isLoading: false
            };
        }
        case AppAction.SIGN_IN: {
            console.log("Action: " + AppAction.SIGN_IN);
            return {
                ...state,
                isLoading: false,
                user: action.user,
            };
        }
        case AppAction.SIGN_OUT: {
            console.log("Action: " + AppAction.SIGN_OUT);
            return {
                ...state,
                user: undefined
            };
        }
        case AppAction.APP_ERROR: {
            console.log("Action: " + AppAction.APP_ERROR);
            return {
                ...state,
                error: action.error
            };
        }
        default:
            return state;
    }
};